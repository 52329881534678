

























































import { Component, Vue, Prop } from "vue-property-decorator";
import IncidentModule from "@/store/modules/incident";
import { Incident } from "@/store/models/incident";
import { IncidentStatus } from "@/utils/constants";
import { eventHub } from "@/utils/eventHub";

import incident from "../../../store/api/incident";

@Component({
  components: {},
})
export default class StationPopup extends Vue {
  @Prop({
    default: [{}],
  })
  info: any;

  closePopup(e: any) {
    this.$emit("close-popup", e);
  }
}
