


























import { Vue, Component, Prop } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import VueEasyLightbox from "vue-easy-lightbox";

@Component({
  components: {
    Input,
    Button,
    Form,
    VueEasyLightbox
  }
})
export default class Gallery extends Vue {
  @Prop({ default: [] })
  imgs!: any[];
  visible = false;
  handleHide() {
    this.visible = false;
  }
  showImg(index: any) {
    this.index = index;
    this.visible = true;
  }

  index = 0;
}
