




import { Component, Vue } from "vue-property-decorator";
import RouteModule from "@/store/modules/route";
import { Route } from "@/store/models/route";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {},
})
export default class RouteOverlay extends Vue {
  color = "#cc0000";
  // color="red"
  created() {}
  updated() {
    const that = this;
    this.$nextTick(function() {
      // Code that will run only after the entire view has been re-rendered

      that.$emit("update-route");
    });
  }
  get routes() {
    return RouteModule.loadRoute;
  }
  onWebSocketPatrollUpdate(e: Route[]) {
    //IncidentModule.setSelectedIncidents();
  }
}
