






































import { Component, Vue } from "vue-property-decorator";
import IncidentModule from "@/store/modules/incident";
import { Incident } from "@/store/models/incident";
import { IncidentStatus } from "@/utils/constants";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {}
})
export default class PanicModeOverlay extends Vue {
  color = "#cc0000";
  // color="red"
  created() {}
  updated() {
    const that = this;
    this.$nextTick(function() {
      // Code that will run only after the entire view has been re-rendered

      that.$emit("update-panic-mode");
    });
  }
  get panicModes() {
    return IncidentModule.panicModes;
  }
  onWebSocketPatrollUpdate(e: Incident[]) {
    //IncidentModule.setSelectedIncidents();
  }
}
