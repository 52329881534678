
























import { Component, Vue, Watch } from "vue-property-decorator";
import incidentListUI from "@/views/incident/subComponents/incidentListUI.vue";
import liveMonitoringList from "@/views/incident/subComponents/liveMonitoringList.vue";
import sectionList from "@/views/incident/subComponents/sectionList.vue";
import stopList from "@/views/incident/subComponents/stopList.vue";
import assigned from "@/views/incident/subComponents/assigned.vue";
import rejected from "@/views/incident/subComponents/rejected.vue";
import IncidentModule from "@/store/modules/incident";
import { IncidentStatus } from "@/utils/constants";
import incident from "../../store/api/incident";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {
    incidentListUI,
    sectionList,
    stopList,
    assigned,
    rejected,
    liveMonitoringList,
  },
})
export default class Rightdash extends Vue {
  //activeName = IncidentModule.selectedIncidentTab;

  get activeName() {
    return IncidentModule.selectedIncidentTab;
  }

  set activeName(val: any) {
    IncidentModule.setSelectedIncidentTab(val);
  }

  get status() {
    return IncidentStatus;
  }

  created() {
    eventHub.$on("update-tab", this.updateTab);
    eventHub.$on("change-tab", this.changeTab);
  }

  changeTab(e: IncidentStatus) {
    console.log(this.activeName);

    this.activeName = e;
    //IncidentModule.setSelectedIncidentTab(this.activeName);
    eventHub.$emit("incident-tab-changed", this.activeName);
  }
  updateTab(e: IncidentStatus) {
    console.log(this.activeName);
    this.activeName = e;
    //IncidentModule.setSelectedIncidentTab(this.activeName);
    eventHub.$emit("incident-tab-changed", this.activeName);
  }

  handleClick(tab: any, event: any) {
    console.log(this.activeName);
    //IncidentModule.setSelectedIncidentTab(this.activeName);
    eventHub.$emit("incident-tab-changed", this.activeName);
  }
}
