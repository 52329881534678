























import {
  Vue,
  Component,
  Prop,
  Watch,
  Model,
  Emit
} from "vue-property-decorator";

@Component
export default class CallBackPagination extends Vue {
  @Prop({ default: 1 })
  currentPage!: number;

  cp: number = 1;
  oldcp: number = 1;

  @Prop({ default: 0 })
  totalData!: number;

  @Prop({ default: 5 })
  pageSize!: number;

  @Prop({ default: "" })
  status!: string;

  @Prop()
  public task!: (title: String, pageSize: number, cp: number) => void;

  get totalPage() {
    let tp = Math.ceil(this.totalData / this.pageSize);
    return tp;
  }

  onPreviousButtonClicked(e: any) {
    let newPage = parseInt("" + this.cp) - 1;
    if (newPage > 0) {
      this.cp = newPage;
      this.onPageUpdate();
    }
  }

  onKeyPress(evt: any) {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      key = evt.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  onNextButtonClicked(e: any) {
    let newPage = parseInt("" + this.cp) + 1;
    if (newPage <= this.totalPage) {
      this.cp = newPage;
      this.onPageUpdate();
    }
  }

  onTextChanged(e: any) {
    if (this.cp == 0 || this.cp > this.totalPage) {
      this.cp = this.oldcp;
    } else if (this.cp <= this.totalPage) {
      this.onPageUpdate();
    }
  }

  error() {
    this.$message({
      showClose: true,
      message: "Please enter page below total pages",
      type: "error"
    });
  }

  @Watch("cp")
  onCpChange(val: number, oldVal: number) {
    if (val != oldVal) {
      this.$emit("currentPage", val);
    }
  }

  @Watch("currentPage")
  onCurrentPageChange(val: number, oldVal: number) {
    this.cp = val;
  }

  async onPageUpdate() {
    this.oldcp = this.cp;
    let res = await this.task(this.status, this.pageSize, this.cp);
  }

  created() {}
}
