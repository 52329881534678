



































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import IncidentModule from "@/store/modules/incident";

import { Incident } from "@/store/models/incident";
import { IncidentStatus } from "@/utils/constants";
import { eventHub } from "@/utils/eventHub";
import helper from "@/utils/helpers";
import MessagingModule from "@/store/modules/messaging";
import pagination from "@/components/UIComponents/Pagination.vue";
import CallBackPagination from "@/components/UIComponents/CallBackPagination.vue";
import { MetaModule, NotificationModule, CommonStore } from "@/store/modules";
import { LiveData } from "@/store/models/meta";
import moment from "moment";

@Component({
  components: { pagination, CallBackPagination },
})
export default class LiveMonitoringList extends Vue {
  data() {
    return {
      options: [
        {
          value: "0",
          label: "Select Rules",
        },
        {
          value: "1",
          label: "Speed Violation",
        },
        {
          value: "2",
          label: "Route Violation",
        },
      ],
      value: "0",
    };
  }
  public livedata: [] = [];
  @Prop()
  activeTab!: String;

  @Prop({ default: IncidentStatus.Created })
  status!: IncidentStatus;

  currentPage: number = 1;
  onCurrentPageChanged(page: number) {}
  qData: any[] = [];
  totalData = 0;
  pageSize = IncidentModule.incidentPageSize;
  activeList: any = {
    active: true,
  };
  filterObj = {
    name: "",
    rules: "",
  };
  @Watch("liveMonitoringData")
  onLiveMonitoringUpdate(data: any, oldVal: any) {
    this.livedata = data;
  }

  get liveMonitoringData() {
    return CommonStore.liveMonitoringData;
  }
  onc(e: any) {}
  get SelectedVehicleId() {
    var a = CommonStore.vehicleId;
    return a;
  }

  get SelectedRouteId() {
    var routeId = CommonStore.routeId;
    return routeId;
  }

  get incidents() {
    //can be deleted
    let inc = MetaModule.sections;
    if (!inc) return [];

    let filteredIncident = inc;
    //filteredIncident.sort(this.compare);

    return filteredIncident;
  }

  created() {
    this.myTask(this.status, this.pageSize, 1);

    eventHub.$on(
      "incidents-from-notification",
      this.onIncidentFromNotification
    );

    eventHub.$on("incident-tab-changed", this.onTabChanged);
    eventHub.$on("incident-status-updated", this.onIncidentStatusUpdated);
    eventHub.$on("scroll-to-active-section", this.scrollToActiveList);
  }

  mounted() {}

  onIncidentStatusUpdated(e: any) {
    let index = this.qData.findIndex((x) => x.IncidentId == e);
    if (index > -1) {
      this.qData.splice(index, 1);

      eventHub.$emit("remove-incident-from-map", e);
      this.myTask(this.status, this.pageSize, 1);
    }
  }

  async onTabChanged(e: any) {
    //if (e == this.status) {
    // e is status param orginally generated on tab change.

    this.currentPage = 1;
    let d = await this.myTask(this.status, this.pageSize, 1);
    eventHub.$emit("map-update-from-incident-list", this.activeTab);
    //}
  }

  async onIncidentFromNotification(data: any) {
    let _status = "";
    if (data.Data.length > 0) {
      _status = data.Data[0].Status;
    }
    if (_status == this.status) {
      this.qData = data.Data;
      this.totalData = data.Count;
      this.currentPage = data.Page;

      const a = NotificationModule.activeNotification;

      if (NotificationModule.activeNotification) {
        const e = NotificationModule.activeNotification;
        eventHub.$emit("notification-item-clicked", e);
        NotificationModule.setActiveNotification(null);
      }

      eventHub.$emit("change-tab", this.status);
      eventHub.$emit("incident-tab-changed", this.qData);
    }
  }

  async myTask(status: string, pageSize: number, cp: number) {
    let t = CommonStore.liveMonitoringData;

    let p = this.livedata;
    let route_id = CommonStore.routeId;
    let data = await IncidentModule.getLiveDataByPaging({
      PageNo: cp,
      PageSize: this.pageSize,
      IncidentStatus: this.status,
    });
    let filteredObject = data.filter((x) => x.route == route_id);
    this.qData = data;

    this.totalData = data.length;
    eventHub.$emit("incident-page-changed", this.qData);
    return t;
  }

  filter() {
    let t = CommonStore.liveMonitoringData;
    var b = this.filterObj.rules;
    if (b === "1") {
      let rule_one = "Speed Violation".toLowerCase();
      t = t.filter((x) => x.title.toLowerCase().includes(rule_one));
    } else if (b === "2") {
      let rule_two = "Route Violation".toLowerCase();
      t = t.filter((x) => x.title.toLowerCase().includes(rule_two));
    } else {
      t;
    }
    this.qData = t;
  }

  onFilterClicked() {
    let t = CommonStore.liveMonitoringData;
    var b = this.filterObj.name.toLowerCase();
    t = t.filter((x) => x.title.toLowerCase().includes(b));
    this.qData = t;
  }

  compare(x: any, y: any): number {
    if (x.HistoryDatetime < y.HistoryDatetime) return 1;
    if (x.HistoryDatetime > y.HistoryDatetime) return -1;
    return 0;
  }

  get Currenttime() {
    return IncidentModule.lastSyncedDate;
  }

  momentDate(date: any) {
    return helper.toMomentDate(date);
  }

  mouseOut(e: any) {
    eventHub.$emit("mouse-out", e);
  }

  mouseOver(e: any) {
    eventHub.$emit("mouse-over", e);
  }

  click(e: any) {
    CommonStore.setLiveVehicleId(e.deviceId);
    eventHub.$emit("live-list-clicked", e);
  }

  // onMessageButtonClicked(e: any) {
  //   MessagingModule.selectIncident(e);
  //   eventHub.$emit("show-message-box", e);
  // }
  scrollToActiveList(e: any) {
    var activeList: any = this.$el.querySelector(
      "#" + this.activeTab + "-" + e.index
    );

    if (activeList) {
      //activeList.scrollIntoView();
      activeList.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
}
