







































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import IncidentModule from "@/store/modules/incident";
import { Incident } from "@/store/models/incident";
import { IncidentStatus } from "@/utils/constants";
import { eventHub } from "@/utils/eventHub";
//import VueGallery from "vue-gallery";
import incident from "../../../store/api/incident";
import confirmDialog from "@/components/UIComponents/confirmDialog.vue";

@Component({
  components: {
    confirmDialog
  }
})
export default class Popup extends Vue {
  dialogMessage = "";
  dialogType = "";
  dialogVisible = false;

  @Prop({ default: "" })
  selectedIncidentKey = "";
  assignIncidentLoader = false;
  selectedPatrollingId = "";
  index = null;
  public checkList: boolean = false;
  createdStatus = IncidentStatus.Created;
  acknowledgedStatus = IncidentStatus.Acknowledge;
  get IncidentMaterials() {
    const { IncidentMaterials } = this.incident as Incident;
    return IncidentMaterials;
  }

  get materialImages() {
    if (this.selectedIncidentKey != "") {
      const { IncidentMaterials } = this.incident as Incident;
      if (IncidentMaterials) {
        return IncidentMaterials.filter(x => x.Type == "Image").map(
          x => x.FileName
        );
      }
    }
    return [];
  }

  convertToKiloMeter(val: number) {
    return (val / 1000).toFixed(2);
  }
  get isLoading() {
    return IncidentModule.isPatrollingLoading;
  }
  get isIncidentAssigning() {
    return IncidentModule.isIncidentAssigning;
  }
  get incident() {
    if (this.selectedIncidentKey != "") {
      var incs = IncidentModule.incidents.filter(
        x => x.IncidentId == this.selectedIncidentKey
      );
      if (incs.length > 0) {
        return incs[0];
      }
    }

    return {} as Incident;
  }

  get nearestPatrollings() {
     console.log(IncidentModule.nearesetPatrollings)
    return IncidentModule.nearesetPatrollings;
  }
  onMaterialSectionClicked(e: any) {
    this.$emit("material-section-clicked", e);
  }
  countIncidentMaterialType(type: string) {
    
    const IncidentMaterials = IncidentModule.IncidentSelectedMaterials;
    if (IncidentMaterials) {
      return IncidentMaterials.filter(x => x.Type === type).length;
    }
    return 0;
  }

  closePopup(e: any) {
    this.$emit("close-popup", e);
  }

  assignIncidentTo(PatrollingId: string) {
    this.$emit("assign-incident", PatrollingId);
  }

  setStatus(type: any, patrollingId: any) {
    
    this.dialogType = type;

    if (this.dialogType === "ack") {
      this.dialogMessage = "Are you sure you want to acknowledge?";
    } else if (this.dialogType === "reject") {
      this.dialogMessage = "Are you sure you want to reject?";
    } else {
      this.selectedPatrollingId = patrollingId;
      this.dialogMessage = "Are you sure you want to assign?";
    }
    this.dialogVisible = true;
  }

  peformAction(e: any) {
    
    if (this.dialogType === "ack") {
      this.$emit("acknowledge-incident", this.selectedIncidentKey);
    } else if (this.dialogType === "reject") {
      this.$emit("reject-incident", this.selectedIncidentKey);
    } else {
      this.$emit("assign-incident", this.selectedPatrollingId);
    }
    
    this.dialogVisible = false;
  }
}
