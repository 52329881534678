



















































import { Component, Vue } from "vue-property-decorator";
import IncidentModule from "@/store/modules/incident";
import { Incident } from "@/store/models/incident";
import { IncidentStatus } from "@/utils/constants";
import { eventHub } from "@/utils/eventHub";
import confirmDialog from "@/components/UIComponents/confirmDialog.vue";

@Component({
  components: {
    confirmDialog
  }
})
export default class PatrolOverlay extends Vue {
  color = "#0d9b94";
  dialogMessage = "Are you sure you want to dismiss the patrolling ?";
  dialogueVisible = false;
  selectedPatrollingId:any="";

  // color="red"
  created() {}
  updated() {
    const that = this;
    this.$nextTick(function() {
      // Code that will run only after the entire view has been re-rendered
      that.$emit("update-patroll");
    });
  }
  get patrolls() {
    return IncidentModule.patrollings;
  }
  showDialogue(e: any) {
    this.selectedPatrollingId=e;
    this.dialogueVisible = true;
  }
  stopPatrolling(e: any) {
    IncidentModule.stopPatrolling(e);
    //
  }
  peformAction(e: any) {
    this.stopPatrolling(this.selectedPatrollingId);
    this.dialogueVisible = false;
  }
  onWebSocketPatrollUpdate(e: Incident[]) {

    //IncidentModule.setSelectedIncidents();
  }
}
