

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class Popup extends Vue {
  //visible = false;
  isVisible = false;

  @Prop()
  visible: boolean = false;

  @Watch("visible")
  onVisibleChange(o: any, n: any) {
    this.isVisible = this.visible;
  }
  @Watch("isVisible")
  onIsVisibleChanged(o: any, n: any) {
    // to update prop visible
    this.$emit("update:visible", this.isVisible);
  }

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: "" })
  message!: string;

  onCloseButtonClicked(e: any) {
    this.$emit("update:visible", false);
  }
  onYesButtonClick(e: any) {
  
  this.$emit("yes-button-clicked",e);
  }
}
