







































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import IncidentModule from "@/store/modules/incident";

import { Incident } from "@/store/models/incident";
import { IncidentStatus } from "@/utils/constants";
import { eventHub } from "@/utils/eventHub";
import helper from "@/utils/helpers";
import MessagingModule from "@/store/modules/messaging";
import pagination from "@/components/UIComponents/Pagination.vue";
import CallBackPagination from "@/components/UIComponents/CallBackPagination.vue";
import { NotificationModule } from "@/store/modules";
@Component({
  components: { pagination, CallBackPagination },
})
export default class incidentListUI extends Vue {
  @Prop()
  activeTab!: String;

  @Prop({ default: IncidentStatus.Created })
  status!: IncidentStatus;

  currentPage: number = 1;
  onCurrentPageChanged(page: number) {}
  qData: any[] = [];
  totalData = 0;
  pageSize = IncidentModule.incidentPageSize;
  activeList: any = {
    active: true,
  };
  onc(e: any) {}
  get SelectedIncidentId() {
    return IncidentModule.selectedIncidentId;
  }

  get incidents() {
    //can be deleted
    let inc = IncidentModule.incidents;
    if (!inc) return [];

    let filteredIncident = inc.filter((x) => x.Status == this.status);
    filteredIncident.sort(this.compare);

    return filteredIncident;
  }

  created() {
    if (this.status == this.activeTab) {
      this.myTask(this.status, this.pageSize, 1);
    }
    eventHub.$on(
      "incidents-from-notification",
      this.onIncidentFromNotification
    );

    eventHub.$on("incident-tab-changed", this.onTabChanged);
    eventHub.$on("incident-status-updated", this.onIncidentStatusUpdated);
    eventHub.$on("scroll-to-active-incident", this.scrollToActiveList);
  }

  mounted() {}

  onIncidentStatusUpdated(e: any) {
    let index = this.qData.findIndex((x) => x.IncidentId == e);
    if (index > -1) {
      this.qData.splice(index, 1);

      eventHub.$emit("remove-incident-from-map", e);
      this.myTask(this.status, this.pageSize, 1);
    }
  }

  async onTabChanged(e: any) {
    if (e == this.status) {
      // e is status param orginally generated on tab change.

      this.currentPage = 1;
      let d = await this.myTask(this.status, this.pageSize, 1);
      eventHub.$emit("map-update-from-incident-list", this.activeTab);
    }
  }

  async onIncidentFromNotification(data: any) {
    let _status = "";
    if (data.Data.length > 0) {
      _status = data.Data[0].Status;
    }
    if (_status == this.status) {
      this.qData = data.Data;
      this.totalData = data.Count;
      this.currentPage = data.Page;

      const a = NotificationModule.activeNotification;

      if (NotificationModule.activeNotification) {
        const e = NotificationModule.activeNotification;
        eventHub.$emit("notification-item-clicked", e);
        NotificationModule.setActiveNotification(null);
      }

      eventHub.$emit("change-tab", this.status);
      eventHub.$emit("incident-tab-changed", this.qData);
    }
  }

  async myTask(status: string, pageSize: number, cp: number) {
    //IncidentModule.incidentOrComplain);

    var status = IncidentModule.incidentOrComplain;
    if (status == "incident") {
      let data = await IncidentModule.getIncidentsByPaging({
        PageNo: cp,
        PageSize: this.pageSize,
        IncidentStatus: this.status,
      });

      this.qData = data.Data;

      this.totalData = data.Count;
      eventHub.$emit("incident-page-changed", this.qData);

      return data;
    } else {
      let data = await IncidentModule.getComplainByPaging({
        PageNo: cp,
        PageSize: this.pageSize,
        IncidentStatus: this.status,
      });

      this.qData = data.Data;
      this.totalData = data.Count;
      eventHub.$emit("incident-page-changed", this.qData);

      return data;
    }
  }

  compare(x: any, y: any): number {
    if (x.HistoryDatetime < y.HistoryDatetime) return 1;
    if (x.HistoryDatetime > y.HistoryDatetime) return -1;
    return 0;
  }

  get Currenttime() {
    return IncidentModule.lastSyncedDate;
  }

  momentDate(date: any) {
    return helper.toMomentDate(date);
  }

  mouseOut(e: any) {
    eventHub.$emit("mouse-out", e);
  }

  mouseOver(e: any) {
    eventHub.$emit("mouse-over", e);
  }

  click(e: any) {
    eventHub.$emit("incident-list-clicked", e);
  }

  onMessageButtonClicked(e: any) {
    // MessagingModule.selectIncident(e);
    eventHub.$emit("show-message-box", e);
  }
  scrollToActiveList(e: any) {
    var activeList: any = this.$el.querySelector(
      "#" + this.activeTab + "-" + e.index
    );
    console.log(activeList);
    if (activeList) {
      //activeList.scrollIntoView();
      activeList.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
}
