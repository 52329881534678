














import { Component, Vue } from "vue-property-decorator";
import IncidentModule from "@/store/modules/incident";
import { Incident } from "@/store/models/incident";
import { IncidentStatus } from "@/utils/constants";
import { eventHub } from "@/utils/eventHub";
import { Category } from "@/store/models/userInfo";
import Style from "ol/style/Style";
import incident from "../../../../store/api/incident";

@Component({
  components: {},
})
export default class IncidentOverlay extends Vue {
  updatedIncident: Incident[] = [];

  created() {
    eventHub.$on("socket-incident", this.onWebSocketIncidentUpdate);
  }

  updated() {
    const that = this;
    this.$nextTick(function() {
      // Code that will run only after the entire view has been re-rendered
      this.$emit("update-map");
    });
  }

  loadIcon(type: string) {
    const path = require("@/assets/" + type + ".svg");
    const url = "url(" + path + ") no-repeat center center";
    return url;
  }

  onWebSocketIncidentUpdate(e: Incident[]) {
    // var that = this;
    // var inc = setInterval(() => {
    //   that.$emit("update-map");
    //   clearInterval(inc);
    // }, 2000);
    // this.$emit("update-map");
    //IncidentModule.setSelectedIncidents();
  }

  get incidents() {
    return []; //IncidentModule.selectedIncidents;
  }
}
