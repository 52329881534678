









































































import { Component, Vue } from "vue-property-decorator";
import IncidentModule from "@/store/modules/incident";

import { Incident } from "@/store/models/incident";
import { IncidentStatus } from "@/utils/constants";
import { eventHub } from "@/utils/eventHub";
import helper from "@/utils/helpers";
import MessagingModule from "@/store/modules/messaging";
import pagination from "@/components/UIComponents/Pagination.vue";
@Component({
  components: { pagination },
})
export default class currentlist extends Vue {
  status = IncidentStatus.Assigned;
  qData: any[] = [];
  get incidents() {
    let inc = IncidentModule.incidents;
    if (!inc) return [];

    let filteredIncident = inc.filter((x) => x.Status == this.status);
    filteredIncident.sort(this.compare);
    return filteredIncident;
  }
  compare(x: any, y: any): number {
    if (x.HistoryDatetime < y.HistoryDatetime) return 1;
    if (x.HistoryDatetime > y.HistoryDatetime) return -1;
    return 0;
  }
  get Currenttime() {
    return IncidentModule.lastSyncedDate;
  }
  get SelectedIncidentId() {
    return IncidentModule.selectedIncidentId;
  }
  momentDate(date: any) {
    return helper.toMomentDate(date);
  }
  mouseOut(e: any) {
    eventHub.$emit("mouse-out", e);
  }
  mouseOver(e: any) {
    eventHub.$emit("mouse-over", e);
  }
  click(e: any) {
    eventHub.$emit("incident-list-clicked", e);
  }
  onMessageButtonClicked(e: any) {
    // MessagingModule.selectIncident(e);
    eventHub.$emit("show-message-box", e);
  }
}
